<template>
    <div class="swiper-container">
        <div class="swiper-wrapper">
            <div
                    class="swiper-slide"
                    style="background-image:url(https://swiperjs.com/demos/images/nature-1.jpg)"
            >
                <div class="swiper-slide-item">¥122</div>
                <div class="swiper-slide-item1">explore</div>
            </div>
            <div
                    class="swiper-slide"
                    style="background-image:url(https://swiperjs.com/demos/images/nature-2.jpg)"
            ><div class="swiper-slide-item">122</div><div class="swiper-slide-item1">explore</div></div>
            <div
                    class="swiper-slide"
                    style="background-image:url(https://swiperjs.com/demos/images/nature-3.jpg)"
            ><div class="swiper-slide-item">122</div><div class="swiper-slide-item1">explore</div></div>
            <div
                    class="swiper-slide"
                    style="background-image:url(https://swiperjs.com/demos/images/nature-4.jpg)"
            ><div class="swiper-slide-item">122</div><div class="swiper-slide-item1">explore</div></div>
            <div
                    class="swiper-slide"
                    style="background-image:url(https://swiperjs.com/demos/images/nature-5.jpg)"
            ><div class="swiper-slide-item">122</div><div class="swiper-slide-item1">explore</div></div>
            <div
                    class="swiper-slide"
                    style="background-image:url(https://swiperjs.com/demos/images/nature-6.jpg)"
            ><div class="swiper-slide-item">122</div><div class="swiper-slide-item1">explore</div></div>
        </div>
        <!-- Add Pagination -->
        <div class="swiper-pagination"></div>
    </div>
</template>

<script>
    // import Swiper from 'swiper'
    import 'swiper/swiper-bundle.css'
    import 'swiper/swiper-bundle.min.css'
    import 'swiper/swiper-bundle.min.js'
    import 'swiper/swiper.min.css'

    import {Swiper, Pagination, Autoplay, EffectCube, EffectCoverflow} from 'swiper'

    Swiper.use([Pagination, Autoplay, EffectCube, EffectCoverflow])
    export default {
        name: "swipers",

        mounted() {
            this.$nextTick(() => {
                let swiper = new Swiper('.swiper-container', {
                    effect: 'coverflow',
                    grabCursor: true,
                    centeredSlides: true,
                    slidesPerView: 'auto',
                    observer: true,
                    observeParents: true,
                    coverflowEffect: {
                        rotate: 50,
                        stretch: 0,
                        depth: 100,
                        modifier: 1,
                        slideShadows: true,
                    },
                    // autoplay:{
                    //     delay:2000,
                    //     disableOnInteraction: false,
                    // },
                    pagination: {
                        el: '.swiper-pagination',
                    },
                });
            })

        }
    }
</script>

<style scoped>
    .swiper-container {
        width: 100%;
        padding-top: .5rem;
        padding-bottom: 1rem;
    }

    .swiper-slide {
        border-radius: .2rem;
        background-position: center;
        background-size: cover;
        width: 70%;
        height: 8rem;
        position: relative;
        /*background-image: url("../assets/t.jpg");*/
    }

    .swiper-slide-item {
        position: absolute;
        z-index: 88;
        left: .3rem;
        top: .3rem;
        padding: .05rem .35rem .08rem;
        background: rgba(96, 99, 104, .8);
        color: white;
        border-radius: .3rem
    }
    .swiper-slide-item1{
        background: white;
        position: absolute;
        bottom:.2rem;
        left:.2rem;
        padding:.1rem .35rem .15rem;
        border-radius: .1rem;
    }

    /*.swiper-container {*/
    /*    width: 300px;*/
    /*    height: 300px;*/
    /*    padding: 50px;*/
    /*}*/

    /*.swiper-slide {*/
    /*    background-position: center;*/
    /*    background-size: cover;*/
    /*    width: 300px;*/
    /*    height: 300px;*/
    /*}*/
</style>
