<template>
    <div @scroll="scrollIt" id="op" style="font-size: .3rem;overflow: auto;height:100%;background: rgb(250,250,252)">
        <div style="position:fixed;top:0;z-index:0;width:100vw;" id="ops">
            <div class="ko top">
                <p @click="$router.push('point')">我的墨豆 {{user.integral}}</p>
                <p @click="$router.push('record')" style="display: flex;align-items: center">兑换记录
                    <van-icon name="arrow"/>
                </p>
            </div>
        </div>
        <div style="height:1rem;background: transparent;padding: .2rem .3rem;">

        </div>
        <div style="border-radius: .1rem;padding: .2rem;margin: .3rem;width:calc(100vw - 1rem);background: white">
            <h3 style="padding: .1rem 0 0">套餐订购</h3>
            <div v-for="item in gymSet" style="display: flex;margin-top: .2rem;">
                <div style="height:1.5rem;width:1.5rem;display:flex;flex-direction:column;justify-content:center;align-items:center;border-radius: .1rem;">
                    <p style="width:100%;text-align:center;font-family: 'frozen', serif;background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/209981/6963bbf342d87b3a2150bd8f59682b89.jpg);-webkit-background-clip:text;background-size:contain;font-weight: 900;color: transparent;font-size:.7rem;flex:1;line-height: 1rem">
                        健</p>
                    <p style="height:.5rem;color:black">🌟 {{item.integral}}</p>
                </div>
                <div style="padding:0 .2rem;flex:1;margin-top: .1rem">
                    <div style="margin-bottom: .1rem;font-weight: bold">兑换{{item.days}}天套餐</div>
                    <div style="color:gray">健身房{{item.title}}</div>
                </div>
                <div style="width:1.5rem;line-height: 1.3rem;text-align: center">
                    <van-button type="primary" size="mini" style="width: 100%;" round @click="exchangeGYM(item.id)">兑换
                    </van-button>
                </div>
            </div>
        </div>
        <div v-if="goods.length!==0" class="gift">
            <h3 style="padding: .3rem 0 0">墨豆好物</h3>
            <!--            <swiper></swiper>-->
            <div class="gift-list">
                <div v-for="(item,index) in goods" @click="turnTo(item)" :key="index" class="single-gift">
                    <img :src="item.cover[0]"
                         style="width: 100%;height:3rem;border-top-left-radius:.2rem;border-top-right-radius:.2rem"/>
                    <div style="border-radius: .1rem;background: #fdfdfd;padding:.13rem 0">
                        <h4 style="padding:0 .3rem;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;">
                            {{item.name}}
                        </h4>
                        <div style="color:#a9a9a9;font-size:.25rem;margin:.1rem 0;padding:0 .3rem;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;">
                            {{item.sub_name}}
                        </div>
                        <p style="padding:0 .3rem;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;">
                            🌟 {{item.integral}}
                        </p>
                    </div>

                </div>
            </div>
            <div style="text-align: center;height:.7rem;">
                <van-loading v-show="loading"/>
            </div>
        </div>
        <div v-else>
            <van-empty description="暂无礼物"/>
        </div>

    </div>
</template>

<script>
    import Notify from "vant/lib/notify";
    import swiper from './swipers'
    import {Dialog, Toast} from 'vant';

    export default {
        name: "shop",
        components: {swiper},
        data() {
            return {
                user: {},

                goods: [],
                page: 0,
                loading: true,

                fixedInTop: false,

                gymSet: [],

                scroll_bar:0
            }
        },
        activated(){
            document.getElementById('op').scrollTop=this.scroll_bar;
        },
        mounted() {
            this.getUser();
            this.getGoods(++this.page);
        },
        methods: {
            changeColor() {


            },
            exchangeGYM(package_id) {
                Dialog.confirm({
                    title: '兑换套餐',
                }).then(() => {
                    this.$axios.post('miniprogram/exchangeOpenlockPackage', {
                        package_id
                    }).then((res) => {
                        let ins=(package_id===1?200:600)
                        this.user.integral=Number(this.user.integral)-ins

                            Toast(res.data.content);
                    })
                }).catch(() => {
                })
                //
            },
            scrollIt(e) {
                let e_t = e.target.scrollTop;
                let e_h = e.target.scrollHeight;
                let e_c = e.target.clientHeight;
                if (e_t + e_c >= e_h) {
                    this.loading = true;
                    this.getGoods(++this.page)
                }
                let header = document.getElementById('ops');
                let scrollTop = document.getElementById('op').scrollTop
                let opacity;
                // 数字200表示到多少px之后会变成不透明
                // 透明度初始为1，随着滚动逐渐为0
                opacity = (1 - (scrollTop / 100) < 0) ? 0 : 1 - (scrollTop / 100);
                // // 透明度初始为0，随着滚动逐渐为1
                // opacity = (scrollTop / 200 > 1) ? 1 : scrollTop / 200;
                // if(scrollTop>72)
                // {
                //     this.fixedInTop=true
                // }else{
                //     this.fixedInTop=false
                // }
                header.style.opacity = opacity;
                this.scroll_bar=scrollTop
            },
            getUser() {
                this.$axios.get('miniprogram/integralUser').then((res) => {
                    if (res.data.code === '0000') {
                        this.user = res.data.content.user
                    }
                });
                this.$axios.get('miniprogram/openlockPackage').then((res) => {
                    if (res.data.code === '0000') {
                        this.gymSet = res.data.content.packages
                    }
                })
            },
            getGoods() {
                this.$axios.get('miniprogram/goodsList?page=' + this.page + '&size=10' + '&category_id=0').then((res) => {
                    if (res.data.code === '0000') {
                        // alert(Math.ceil(res.data.content.count / 10))
                        // alert(this.page)
                        if (Math.ceil(res.data.content.count / 10) > this.page) {
                            // this.d(() => {
                            this.goods = [...res.data.content.goods, ...this.goods];
                            // })
                            this.loading = false
                        } else if (Math.ceil(res.data.content.count / 10) === this.page) {
                            if (res.data.content.goods.length > (this.goods.length % 10)) {
                                // this.d(() => {
                                let temp_items = res.data.content.goods.splice(this.goods.length);
                                this.goods = [...temp_items, ...this.goods];
                                --this.page
                                // })
                            } else {
                                // this.d(() => {
                                Notify({
                                    message: '没有更多了',
                                    color: '#ad0000',
                                    background: '#ffe1e1',
                                    duration: 1000
                                });
                                --this.page
                                // });
                            }
                            this.loading = false
                        }
                    } else {
                        // this.d(() => {
                        Notify({
                            message: '拉取更多失败',
                            color: '#ad0000',
                            background: '#ffe1e1',
                            duration:1000
                        });
                        --this.page
                        this.loading = false
                        // });
                    }
                })
            },
            turnTo(item) {
                this.$router.push({
                    path: 'gift',
                    query: {
                        id: item.id
                    }
                })
            },
            d(c) {
                let st = setTimeout(() => {
                    clearTimeout(st);
                    st = null;
                    c ? c() : '';
                    this.loading = false
                }, 1000)
            }
        }
    }
</script>

<style scoped>
    .ko {
        font-size: .3rem;
        display: flex;
        justify-content: space-between;
    }

    .top {
        background: rgb(254, 249, 231);
        /*padding: .2rem .3rem .6rem;*/
        padding: .2rem .3rem;
        height: 1rem;
        line-height: 1rem;
    }

    .member {
        position: absolute;
        width: calc(100vw - 1.2rem);
        padding: .2rem .3rem;
        margin: .1rem .3rem;
        background: rgb(88, 88, 88);
        border-radius: .1rem;
        bottom: -.5rem;
        color: white;
    }

    .gift {
        /*margin-top: .5rem;*/
        z-index: 10;
        padding: 0 .2rem;
        text-align: center;
    }

    .gift-list {
        display: grid;
        grid-template-columns: repeat(2, 50%);
        justify-items: center;
        text-align: left;
    }

    .single-gift {
        width: 3.1rem;
        height: 4.8rem;
        border-radius: .2rem;
        margin-top: .45rem;
        box-shadow: 1px 1px 10px #d8d8d8;
        background: white;
    }

    .fixedInTop {
        position: absolute;
        top: 0;
        width: calc(100% - 0.4rem);
        background: white;
        border-radius: .3rem;
        z-index: 999;
    }

    .unFixedTop {
        position: relative;
        z-index: 999;
    }

    .text {
        background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/209981/6963bbf342d87b3a2150bd8f59682b89.jpg);
        -webkit-background-clip: text;
        background-size: contain;
        width: 100%;
        text-align: center;
        color: transparent;
        font-weight: 900;


    }

    .text::before {
        content: attr(data-heading);
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        background: linear-gradient(45deg, rgba(255, 255, 255, 0) 45%, rgba(255, 255, 255, 0.8) 50%, rgba(255, 255, 255, 0) 55%, rgba(255, 255, 255, 0) 100%);
        -webkit-background-clip: text;
        color: transparent;
        mix-blend-mode: screen;
        animation: shine 1s infinite;
        background-size: 200%;
        text-shadow: 2px 2px 10px rgba(#000, 0.2),
        -2px 2px 10px rgba(#000, 0.2),
        -2px -2px 10px rgba(#000, 0.2);
    }

    @keyframes shine {
        0% {
            background-position: -100%;
        }
        100% {
            background-position: 100%;
        }
    }

</style>
